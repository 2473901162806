import React, { useEffect, useState } from 'react';
import backGround from '../Images/Portfolio/PortfolioHero.png';

import BigEmpire from '../components/BigEmpire';
import { useParams } from 'react-router-dom';
import { portfolioList, useCasesList } from '../helper/Constant';
import Loader from '../components/Loader/Loader';
import desktopScreen from '../Images/Portfolio/desktop.png';
import Slider from 'react-slick';
import demoImage from '../Images/Portfolio/TLA/demo.png';
import { FaCaretDown, FaCaretRight } from 'react-icons/fa';

const Portfolio = () => {
  const { title } = useParams();

  const [currentDetails, setCurrentDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [listOfCases, setListOfCases] = useState([]);
  const [currentCase, setCurrentCase] = useState(null);
  useEffect(() => {
    const current = portfolioList.find((i) => i.project_path === title);
    setCurrentDetails(current);
    setIsLoading(false);

    const filter = useCasesList
      .filter((i) => i.tags.includes(title))
      .map((x, index) => {
        return {
          id: index + 1,
          ...x,
        };
      });

    setListOfCases(filter);
    setCurrentCase(filter[0]);
  }, [title]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div
            style={{
              backgroundImage: `url(${backGround})`,
              width: '100%',
              backgroundSize: 'cover', // Ensures the image covers the entire div
              backgroundRepeat: 'no-repeat', // Prevents image from repeating
              backgroundPosition: 'center', // Centers the image
              height: '100%',
            }}
          >
            <div className="container-xxl px-[10px] py-[150px]">
              <img src={currentDetails.logo} className="min-h-[58px] w-[415px]" />
              <div
                className="text-white font-poppins md:leading-[60px] leading-[50px] text-[40px] md:text-[50px] font-semibold md:font-bold text-left w-full mt-[30px]"
                dangerouslySetInnerHTML={{ __html: currentDetails?.banner_title }}
              />
            </div>
          </div>
          <div className="bg-[#DBE9FC]">
            <div className="container-xxl grid grid-cols-1">
              <div className="py-[50px] grid justify-center gap-x-5 gap-y-[30px] md:gap-y-[50px] lg:gap-y-[80px] sm:grid-cols-2 lg:grid-cols-4 m-auto">
                {currentDetails.project_details?.map((v, i) => {
                  return (
                    <div key={i}>
                      <div className="flex gap-3">
                        <img src={v?.icon} className="h-[45px] w-[44px]" />
                        <div>
                          <div className="font-poppins font-[700] text-[20px] text-[#152C5B]">{v?.title}</div>
                          <div className="font-open-sana text-[20px] leading-[26px]">{v?.value.join(', ')}</div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="my-[50px]">
            <div className="font-poppins font-[700] text-[30px] md:text-[38px] px-2 text-[#314ED9] text-center">Company Background</div>
          </div>
          <div className="container-xxl grid grid-cols-1">
            <div className="pb-10 md:py-0">
              <h1 className="pb-[10px] md:pb-[17px] text-[25px] md:text-[28px] font-[700] text-[#152C5B]">Project Brief</h1>
              <p className="font-open-sana text-[18px] leading-[28px]">{currentDetails?.company_details.project_brief}</p>
            </div>
          </div>
          <div className="my-[50px] container-xxl grid grid-cols-1">
            <div className="grid md:grid-cols-2 justify-center gap-x-10 m-auto">
              <div className="pb-10 md:py-0 flex justify-center items-center">
                <img src={currentDetails?.banner_image} />
              </div>
              <div className="pb-10 md:py-0">
                <h1 className="service-provided pb-[10px] md:pb-[17px] text-[25px] md:text-[28px] font-[700] text-[#152C5B] flex justify-center">Service Provided</h1>
                <div className="grid gap-2 grid-cols-2 font-semibold mx-4 my-8  font-open-sana text-[18px] leading-[28px] ">
                  {currentDetails.company_details.service_provided.map((item, index) => {
                    return (
                      <div key={index} className="pb-[15px]">
                        {item}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="container-xxl grid grid-cols-1">
            <h1 className="pb-[10px] md:pb-[17px] text-[25px] md:text-[38px] font-[700] text-[#152C5B]">Faced Challenges</h1>
            <div className="grid gap-2 grid-cols-2 font-semibold mx-4 my-8  font-open-sana text-[18px] leading-[28px] ">
              {currentDetails.company_details.challenges.map((item, index) => {
                return (
                  <div key={index} className="pb-[15px]">
                    {item}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="container-xxl md:mt-[60px]">
            <div className="pb-[23px] md:py- lg:sticky lg:top-[50px] h-fit">
              <h1 className="pb-[10px] md:pb-[17px] text-[25px] md:text-[38px] font-[700] text-[#152C5B]">Shivay Intelligence Approach</h1>
              <p className="font-open-sana text-[18px] leading-[28px]">{currentDetails.company_details.shivay_approch.description}</p>
            </div>
          </div>
          <div className="container-xxl ">
            <h1 className="pb-[10px] md:pb-[17px] text-[25px] md:text-[28px] font-[700] text-[#152C5B]">Key Features</h1>
            <div className="pb-[50px] grid md:grid-cols-2 justify-center gap-x-10 m-auto font-open-sana text-[18px] leading-[28px]">
              {currentDetails.company_details.shivay_approch.key_fetures.map((item, index) => {
                return (
                  <div key={index}>
                    <div className="flex gap-3 items-center pt-[22px]">
                      <div className="border-t-2 border-[#314ED9] w-[20px] h-[3px]"></div>
                      <div>{item}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="container-xxl md:pt-[60px]">
            <div className="pb-[23px]">
              <h1 className="pb-[10px] md:pb-[17px] text-[25px] md:text-[38px] font-[700] text-[#152C5B]">Results</h1>
              <p className="font-open-sana text-[18px] leading-[28px]">{currentDetails.company_details.results.description}</p>
            </div>
            <div className="pb-10 md:py-0 flex justify-center items-center use-cases">
              <div className="relative w-full h-auto">
                <img src={desktopScreen} alt="Desktop" className="w-full h-auto block" />
                <div className="absolute top-[7.45%] left-[6.45%] w-[86%] h-[66.5%]  rounded-md">
                  <Slider {...settings} className="h-full">
                    {currentDetails.company_details.results.images.map((item, index) => {
                      return (
                        <div className="h-full" key={index}>
                          <img src={item} alt="Slide 3" className="w-full h-full object-cover" />
                        </div>
                      );
                    })}
                    {/* Add more slides as needed */}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
          <BigEmpire />
          {/* {listOfCases.length !== 0 && (
            <div className="container-xxl py-12 ">
              <div className="flex gap-4 flex-col">
                <div>
                  <div className="font-poppins md:text-[36px] text-[28px] font-bold">
                    <span className="text-[#314ED9]">Use Cases of</span> this Services
                  </div>
                  <div className="text-[15px] md:text-[18px] font-normal my-3">
                    Discover how we translate strategic innovation into differentiated capabilities and accelerate bottom-line impacts for fast-growing enterprises and
                    digitally-native companies.
                  </div>
                </div>
                <div className=" grid-cols-2 gap-3 hidden lg:grid">
                  <div className="lg:sticky lg:top-[50px] h-fit">
                    {listOfCases.map((item) => {
                      return (
                        <div
                          key={item.id}
                          className={`mb-4 font-poppins text-[24px] font-semibold cursor-pointer hover:text-[#314ED9] ${
                            currentCase?.id === item.id ? 'text-[#314ED9]' : 'text-black'
                          }`}
                          onClick={() => {
                            setCurrentCase(item);
                          }}
                        >
                          {item.title}
                        </div>
                      );
                    })}
                  </div>
                  <div className="">
                    <div className="p-8 rounded-2xl shadow-[4px_4px_31px_0px_rgba(0,0,0,0.15)] bg-white">
                      <div className="text-[#152C5B] font-poppins md:text-[24px] text-[18px] font-bold">{currentCase?.title}</div>
                      <hr />
                      <div className="text-[15px] md:text-[18px] font-normal my-2">{currentCase?.description}</div>

                      {currentCase?.key_benefits && (
                        <div className="my-2">
                          <div className="text-[#152C5B] font-poppins md:text-[20px] text-[18px] font-semibold pt-2">Key Benefits</div>
                          <hr />
                          <ul>
                            {currentCase?.key_benefits.map((k, i) => {
                              return (
                                <li key={i} className="my-2 list-disc list-inside">
                                  <strong>{k.title}: </strong>
                                  {k.description}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      )}

                      {currentCase?.case_study[title] && (
                        <div className="my-2">
                          <div className="text-[#152C5B] font-poppins md:text-[20px] text-[18px] font-semibold pt-2">Case Study</div>
                          <hr />
                          <div className="text-[15px] md:text-[18px] font-normal my-2">{currentCase?.case_study[title]?.description}</div>

                          <div className="text-[15px] md:text-[18px] font-normal my-2">
                            <span className="font-semibold">Time Duration: </span>
                            {currentCase?.case_study[title]?.implementation_time}
                          </div>
                        </div>
                      )}
                      {currentCase?.technologies && (
                        <div className="my-2">
                          <div className="text-[#152C5B] font-poppins md:text-[20px] text-[18px] font-semibold pt-2">Technologies</div>
                          <hr />
                          <div className="flex gap-3 items-center flex-wrap">
                            {currentCase?.technologies.map((item, index) => {
                              return (
                                <div className="text-[12px] px-3 py-[5px] rounded md:text-[16px] font-normal my-2 bg-[#152C5B] text-white" key={index}>
                                  {item}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="block lg:hidden">
                  {listOfCases.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="p-2"
                        onClick={() => {
                          if (index + 1 === currentCase?.id) {
                            setCurrentCase(null);
                          } else {
                            setCurrentCase(item);
                          }
                        }}
                      >
                        <AccordionItem key={index} title={title} details={item} isOpen={currentCase && currentCase.id === index + 1 ? true : false} />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )} */}
        </>
      )}
    </>
  );
};

export default Portfolio;

const AccordionItem = ({ title, isOpen, details }) => {
  console.log('title', details);
  return (
    <div className="bg-[#F7F7F7] p-4 rounded-2xl  cursor-pointer flex gap-2 justify-between">
      <div>
        <div className="text-[#152C5B] mb-3 sm:text-[24px] text-[18px] font-bold leading-[28px]">{details?.title}</div>
        <div className={`${isOpen ? 'block' : 'hidden'} sm:text-[18px] text-[14px]`}>
          <div className="">
            <div className="p-8 rounded-2xl shadow-[4px_4px_31px_0px_rgba(0,0,0,0.15)] bg-white">
              <div className="text-[15px] md:text-[18px] font-normal my-2">{details?.description}</div>

              {details?.key_benefits && (
                <div className="my-2">
                  <div className="text-[#152C5B] font-poppins md:text-[20px] text-[18px] font-semibold pt-2">Key Benefits</div>
                  <hr />
                  <ul>
                    {details?.key_benefits.map((k, i) => {
                      return (
                        <li key={i} className="my-2 list-disc list-inside">
                          <strong>{k.title}: </strong>
                          {k.description}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
              {details?.case_study[title] && (
                <div className="my-2">
                  <div className="text-[#152C5B] font-poppins md:text-[20px] text-[18px] font-semibold pt-2">Case Study</div>
                  <hr />
                  <div className="text-[15px] md:text-[18px] font-normal my-2">{details?.case_study[title]?.description}</div>

                  <div className="text-[15px] md:text-[18px] font-normal my-2">
                    <span className="font-semibold">Time Duration: </span>
                    {details?.case_study[title]?.implementation_time}
                  </div>
                </div>
              )}
              {details?.technologies && (
                <div className="my-2">
                  <div className="text-[#152C5B] font-poppins md:text-[20px] text-[18px] font-semibold pt-2">Technologies</div>
                  <hr />
                  <div className="flex gap-3 items-center flex-wrap">
                    {details?.technologies.map((item, index) => {
                      return (
                        <div className="text-[12px] px-3 py-[5px] rounded md:text-[16px] font-normal my-2 bg-[#152C5B] text-white" key={index}>
                          {item}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="text-[24px]">{isOpen ? <FaCaretDown /> : <FaCaretRight />}</div>
    </div>
  );
};
